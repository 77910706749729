import React from 'react'
import { SocialIcon } from 'react-social-icons'

const Contact = () => {
    return (
        <main id="contact" class="pt-10">
            <h1 class="flex justify-center text-5xl text-slate-950">Contact</h1>
            <h1 class="flex justify-center text-4xl text-slate-950 pt-5">Social Links</h1>

            <section className="flex gap-10 justify-center p-10">
                <SocialIcon url="https://www.linkedin.com/in/nicholas-gitman/" />
                <SocialIcon url="https://github.com/ngitman/" />
                <SocialIcon url="https://app.joinhandshake.com/profiles/42364612" />
            </section>

        </main>
    )
}

export default Contact