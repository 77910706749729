import React from 'react'
import { Stack, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

const DropdownMenu = () => {
    return (
        <section className="absolute top-full w-max right-0 bg-white p-5 rounded-md">
            <Stack>
                <Stack item>
                    <Link to="2024-usg-election-results">
                        <Button sx={{ fontSize: "18px" }}>
                            2024 Stony Brook USG Election Results
                        </Button>
                    </Link>
                </Stack>
                <Divider />
                <Stack item>
                    <h1 className="text-black">Placeholder</h1>
                </Stack>
                <Divider />
                <Stack item>
                    <h1 className="text-black">Placeholder</h1>
                </Stack>
                <Divider />
            </Stack>
        </section>
    )
}

export default DropdownMenu