import React from 'react'

const AboutMe = () => {
    return (
        <main className="p-10" >
            <h1 id="about-me" className="text-5xl flex justify-center pb-5">About Me</h1>

            <div className="flex flex-col items-center bg-sky-50 p-5 rounded-lg">
                <h2 className="text-3xl">General Info</h2>
                <ul className="text-2xl flex flex-col items-center text-slate-950 leading-loose">
                    <li><b>Name:</b> Nicholas Gitman</li>
                    <li><b>Hometown:</b> New York City</li>
                    <li><b>Education:</b> Stony Brook University</li>
                    <li><b>Degree:</b> B.S. in Computer Science & Applied Mathematics and Statistics</li>
                    <li><b>Expected Graduation:</b> May 2026</li>
                </ul>
            </div>
            <section className="justify-center md:flex lg:flex gap-10 pt-5 sm:grid sm:grid-cols-1">

                <div className="flex flex-col items-center bg-sky-50 p-5 rounded-lg">
                    <h2 className="text-3xl">Hobbies and Interests</h2>
                    <ul className="flex flex-col items-center text-slate-950  text-xl leading-loose">
                        <li>Playing tennis</li>
                        <li>Working out at the gym</li>
                        <li>
                            Coding
                        </li>
                        <li>Playing video games</li>
                        <li>Hanging out with friends</li>
                    </ul>
                </div>

                <div className="flex flex-col items-center bg-sky-50 p-5 rounded-lg">
                    <h2 className="text-3xl">Clubs and Organizations</h2>
                    <ul className="flex flex-col items-center text-slate-950  text-xl leading-loose">
                        <li>Russian Corner, Event Coordinator</li>
                        <li>Stony Brook Computing Society, Member</li>
                        <li>Greeley Hall Council, Fmr. President (2022-2023)</li>
                    </ul>
                </div>

            </section>
        </main >
    )
}

export default AboutMe