import React from 'react'
import { useRef, useEffect } from 'react';
import Typed from 'typed.js';

const Title = () => {
    const el = useRef(null);
    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: ['<p>NicholasGitman.com</p>'],
            typeSpeed: 50,
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, []);

    return (
        <main id="main" className="bg-blue-950 sm:flex sm:flex-col md:flex-row lg:flex-row items-center justify-center sm:w-full  sm:gap-3">
            <img src="/headshot.png" className="drop-shadow-2xl" alt="Headshot of Nicholas Gitman" id="my_image" />
            <span className="sm:text-3xl md:text-7xl lg:text-8xl text-white font-medium text-center sm:w-full" ref={el}></span>
        </main>
    )
}

export default Title