import React from 'react'

import Title from './Title';
import Skills from './Skills';
import AboutMe from './AboutMe';
import Contact from './Contact';

const MainPage = ({ projMouseHover, setProjMouseHover }) => {
    return (
        <main id="main" className="scroll-smooth max-w-screen">
            <Title id="title" />
            <Skills id="skills" />
            <AboutMe />
            <Contact id="contact" />
        </main>
    )
}

export default MainPage