import React from 'react'

const SkillBox = ({ name, src, size = 32 }) => {
    return (
        <div className="flex flex-col items-center">
            <section class={`size-${size} min-w-${size} max-w-${size} max-w-32 max-h-32 flex rounded-lg bg-blue-50 drop-shadow-2xl items-center justify-center`}>
                <img class={`size-full min-w-${size} min-h-${size} object-contain p-3`}
                    src={src}
                    alt={name} />
            </section>
            <p>{name}</p>
        </div>

    )
}

export default SkillBox