import React from 'react'
import data from './electiondata/usg2024.json';
import { Box, Table, TableBody, TableHead, TableRow, TableCell, Paper, TableContainer } from '@mui/material';
import ElectionBox from './ElectionBox';

const Usg2024 = () => {
    const parties = [
        { name: "Dream", color: "#033dfc" },
        { name: "Ace", color: "#fc0303" },
        { name: "Pizza", color: "#fcc603" },
        { name: "Coastal", color: "#17fbff" },
        { name: "Unaffiliated", color: "#c9c9c9" },
        { name: "No Confidence", color: "#262626" },
    ]
    return (
        <main className="min-h-screen max-w-screen p-5">
            <h1 class="text-4xl py-5 text-slate-950">2024 Stony Brook University Undergraduate Student Government Election Results</h1>
            <p class="text-xl">The following are the election results from the 2024 Stony Brook Undergraduate Student Government election. The following include election results for all executive cabinet positions, all Senators, Chief Justice, and all referendum results. The goal of this project is to better visualize the election results along party lines, percentages, and turnouts. </p>
            <p class="text-xl py-5">Data Source: https://www.instagram.com/stonybrookusg/</p>

            <h3 className="text-xl flex justify-center font-bold">Legend:</h3>

            <TableContainer component={Paper} >
                <Table aria-label="legend">
                    <TableHead>
                        <TableCell align="right"><b>Item</b></TableCell>
                        <TableCell align="right"><b>Color or Formatting</b></TableCell>
                    </TableHead>
                    <TableBody>
                        {parties.map(p => (
                            <TableRow>
                                <TableCell align="right">Party: {p.name}</TableCell>
                                <TableCell align="right">
                                    <Box sx={{ bgcolor: p.color, width: 1, height: 10 }} />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell align="right">Incumbent</TableCell>
                            <TableCell align="right">*</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">Winner</TableCell>
                            <TableCell align="right"><b>Bold formatting</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


            <section className="grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 py-5 ">
                {Object.keys(data).map((pos) => (
                    <div className="flex flex-col justify-center border-2">
                        <h1 className="text-xl flex justify-center p-3 font-bold">{pos}</h1>
                        <ElectionBox pos={pos} candidates={data[pos].sort((a, b) => b.votes - a.votes)} parties={parties} />

                    </div>

                ))}
            </section>
        </main>
    )
}

export default Usg2024