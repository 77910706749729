import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import DropdownMenu from './DropdownMenu';
import MobileNav from './MobileNav';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const Header = ({ projMouseHover, setProjMouseHover }) => {
  const [mobileOpened, setMobileOpened] = useState(false);
  const buttonSx = { color: "#f5f5f4", fontSize: "24px" };
  const buttonsText = [
    { name: "Home", to: "/", id: false, noRoute: "header" },
    { name: "About Me", to: "/#about-me", id: true, noRoute: "about-me" },
    { name: "Resume", to: "https://github.com/ngitman/MyResume/blob/main/file.pdf", id: true, noRoute: "" },
    { name: "GitHub", to: "https://github.com/ngitman", id: true, noRoute: "" },
    { name: "Contact", to: "#contact", id: true, noRoute: "contact" }
  ];
  return (
    <header className="max-w-screen sticky top-0 mx-auto p-4 sm:p-6 flex flex-col bg-black z-50">
      <div id="header" className="flex items-center justify-between w-full">
        <Link to="/">
          <Button className="flex flex-col text-left">
            <Typography sx={{ fontSize: { xs: "32px", sm: "48px", md: "56px" }, color: "#f5f5f4" }} textTransform="none">
              NicholasGitman.com
            </Typography>
            <Typography sx={{ fontSize: { xs: "16px", sm: "24px", md: "28px" }, color: "#cbd5e1" }} textTransform="none">
              Welcome to my personal website
            </Typography>
          </Button>
        </Link>

        {/* Desktop Navigation */}
        <div className="lg:flex items-center ml-auto hidden">
          <nav>
            <ul className="flex flex-wrap flex-row space-x-4 sm:space-x-6 md:space-x-10 text-xl sm:text-2xl text-slate-200">
              {buttonsText.map(b => (
                <Link key={b.name} to={b.to}>
                  <Button sx={buttonSx} onClick={!b.id ? null : () => {
                    window.location.href = (b.to);
                  }}>
                    <Typography sx={buttonSx} textTransform="none">{b.name}</Typography>
                  </Button>
                </Link>
              ))}
              <Button className="flex flex-col" sx={buttonSx} onMouseEnter={() => setProjMouseHover(true)} onMouseLeave={() => setProjMouseHover(false)}>
                <Typography sx={buttonSx} textTransform="none">Projects</Typography>
                {projMouseHover && <DropdownMenu />}
              </Button>
            </ul>
          </nav>
        </div>

        {/* Mobile menu button */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ color: "#FFFFFF", mr: 2, display: { xs: 'block', lg: 'none' } }}
          onClick={() => setMobileOpened(!mobileOpened)}
        >
          <MenuIcon />
        </IconButton>
      </div>

      {/* Mobile Navigation */}
      {mobileOpened && (
        <div className="lg:hidden w-full bg-black">
          <MobileNav
            buttonsText={buttonsText}
            buttonSx={buttonSx}
          />
        </div>
      )}
    </header>
  );
}

export default Header