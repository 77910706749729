import React from 'react';
import { useState } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import MainPage from './MainPage';
import Usg2024 from './Usg2024';
//import MenuIcon from '@mui/icons-material/Menu';

function App() {
  const [projMouseHover, setProjMouseHover] = useState(false);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000"
      }
    },
    typography: {
      fontFamily: ["sans-serif"],
    },

  });

  return (
    <ThemeProvider theme={theme}>
      <Layout
        projMouseHover={projMouseHover}
        setProjMouseHover={setProjMouseHover}>
        <Routes>
          <Route path="2024-usg-election-results" element={<Usg2024 />} />
          <Route path="/" element={<MainPage
            projMouseHover={projMouseHover}
            setProjMouseHover={setProjMouseHover}
          />} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
