import React from 'react'

import Header from './Header'
import ThisWebsite from './ThisWebsite';

const Layout = ({ children, projMouseHover, setProjMouseHover }) => {
    return (
        <main className="max-w-screen scroll-smooth">
            <Header projMouseHover={projMouseHover} setProjMouseHover={setProjMouseHover} />
            {children}
            <ThisWebsite />
        </main>
    )
}

export default Layout