import React from 'react'
import SkillBox from './SkillBox';
import { Stack } from '@mui/material';

const Skills = () => {
    const languages = [
        { language: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg", name: "Python" },
        { language: "https://upload.wikimedia.org/wikipedia/en/3/30/Java_programming_language_logo.svg", name: "Java" },
        { language: "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png", name: "JavaScript" },
        { language: "https://upload.wikimedia.org/wikipedia/commons/1/19/C_Logo.png", name: "C" },
        { language: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Logo_C_sharp.svg", name: "C#" },
        { language: "https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg", name: "HTML" },
        { language: "https://upload.wikimedia.org/wikipedia/commons/d/d5/CSS3_logo_and_wordmark.svg", name: "CSS" }
    ];
    const frameworks = [
        { name: "React.js", src: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" },
        { name: "Flask", src: "https://static-00.iconduck.com/assets.00/programming-language-flask-icon-2048x1826-wf5k5ugs.png" }
    ];
    const tools = [
        { name: "TailwindCSS", src: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg" },
        { name: "MaterialUI", src: "https://mui.com/static/logo.png" }
    ];

    return (
        <main id="skills" class="flex flex-col flex-nowrap bg-sky-900 text-white py-5 max-w-screen">
            <h3 class="flex justify-center items-center text-4xl my-5 font-medium border-solid">My Skills:</h3>

            <section class="">
                <Stack className="flex items-center">
                    <Stack item>
                        <h4 class="flex justify-center items-center text-3xl my-5 font-medium border-solid">Languages:</h4>
                        <div className="flex gap-10">
                            {languages.map(l => (
                                <SkillBox src={l.language} name={l.name} />
                            ))}
                        </div>

                    </Stack>
                    <Stack item>
                        <h4 class="flex justify-center items-center text-3xl my-5 font-medium border-solid">Frameworks:</h4>
                        <div className="flex gap-10">
                            {frameworks.map(l => (
                                <SkillBox src={l.src} name={l.name} />
                            ))}
                        </div>

                    </Stack>
                    <Stack item>
                        <h4 class="flex justify-center items-center text-3xl my-5 font-medium border-solid">Tools:</h4>
                        <div className="flex gap-10">
                            {tools.map(l => (
                                <SkillBox src={l.src} name={l.name} />
                            ))}
                        </div>

                    </Stack>
                </Stack>
            </section>
        </main>
    )
}

export default Skills