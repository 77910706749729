import React from 'react'
import SkillBox from './SkillBox';

const ThisWebsite = () => {
    const items = [
        { name: "React.js", src: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" },
        { src: "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png", name: "JavaScript" },
        { name: "TailwindCSS", src: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg" },
        { name: "MaterialUI", src: "https://mui.com/static/logo.png" }
    ];
    return (
        <main className="bg-slate-200 flex flex-col items-center justify-center p-5 gap-5">
            <h1>This website was built using:</h1>
            <section className="flex flex-row gap-10 sm:px-5">
                {items.map(i => (
                    <SkillBox className="max-w-10 max-h-10" name={i.name} src={i.src} size={20} />
                ))}
            </section>
            <footer>By Nicholas Gitman</footer>

        </main>
    )
}

export default ThisWebsite