import React from 'react'
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import DropdownMenu from './DropdownMenu';
import { useState } from 'react';

const MobileNav = ({ buttonSx, buttonsText }) => {
    const [projects, setProjects] = useState(false);
    return (
        <nav className="max-w-screen">
            <ul className="bg-slate-900 flex-row space-x-4 sm:space-x-6 md:space-x-10 text-xl sm:text-2xl text-slate-200">
                {buttonsText.map(b => (
                    <Link key={b.name} to={b.to}>
                        <Button sx={buttonSx} onClick={!b.id ? null : () => {
                            document.getElementById(b.noRoute).scrollIntoView({ behavior: "smooth" });
                        }}>
                            <Typography sx={buttonSx} textTransform="none">{b.name}</Typography>
                        </Button>
                    </Link>
                ))}
                <Button className="flex flex-col" sx={buttonSx} onClick={() => setProjects(!projects)}>
                    <Typography sx={buttonSx} textTransform="none">Projects</Typography>
                    {projects && <DropdownMenu />}
                </Button>
            </ul>
        </nav>
    )
}

export default MobileNav