import React from 'react'
import { useState, useEffect } from 'react';
import { Box, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@mui/material';

const ElectionBox = ({ pos, candidates, parties }) => {
    const [totalVotes, setTotalVotes] = useState(0);
    const [ncVotes, setNcVotes] = useState(0);
    const [winners, setWinners] = useState([]);
    useEffect(() => {
        if (pos !== "Senators") {
            let votes = 0;
            for (const candidate of candidates) {
                votes += candidate["votes"];
                if (candidate === "NC") setNcVotes(candidate["votes"]);
            }
            // Set winners:
            const winner = candidates[0].name;
            setWinners([winner === "NC" ? "" : winner])
            setTotalVotes(votes);
        } else {
            setWinners(() => {
                let small = [];
                console.log(candidates);
                for (let i = 0; i < 23 && i < candidates.length; i++) {
                    const candidateName = candidates[i].name;

                    if (candidateName === "No Confidence")
                        break;
                    else {
                        small.push(candidateName);
                    }
                }
                return small;
            })

            setTotalVotes(2499); // TODO: create a memoization for the President vote
        }
        console.log(winners);
    }, [])
    const getPartyColor = candidate => {
        const candidateParty = candidate["party"];
        if (candidateParty === "")
            return "#c9c9c9";
        else if (candidateParty === "NC")
            return "#262626";

        const color = parties.filter(p => p["name"] === candidateParty)[0]["color"];
        return color;
    }
    return (
        <>
            {pos === "Senators" ? (
                <h4 className="flex justify-center">{winners.length} / 23 Senators elected</h4>
            ) : (null)}
            <TableContainer sx={{ overflowX: "hidden", whiteSpace: 'normal' }}>
                <TableHead>
                    <TableCell align="left"><b>Candidate</b></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"><b>Votes</b></TableCell>
                    <TableCell align="left"><b>Pct.</b></TableCell>
                    <TableCell align="left"></TableCell>
                </TableHead>
                <TableBody>
                    {candidates.map(c => (

                        winners.includes(c.name) ? (
                            <TableRow>
                                <TableCell align="left"><b>{c.name}{c.incumbent ? "*" : ""}</b></TableCell>
                                <TableCell align="left">✅</TableCell>
                                <TableCell align="left"><b>{c.votes}</b></TableCell>
                                <TableCell align="left"><b>{((c.votes / totalVotes) * 100).toFixed(2)}%</b></TableCell>
                                <TableCell align="left" className="overflow-x-hidden">
                                    <Box sx={{ bgcolor: getPartyColor(c), width: (500 * (c.votes / totalVotes).toFixed(2)), height: 12 }} />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell align="left">{c.name}{c.incumbent ? "*" : ""}</TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">{c.votes}</TableCell>
                                <TableCell align="left">{((c.votes / totalVotes) * 100).toFixed(2)}%</TableCell>
                                <TableCell align="left" className="overflow-x-hidden">
                                    <Box sx={{ bgcolor: getPartyColor(c), width: (500 * (c.votes / totalVotes).toFixed(2)), height: 12 }} />
                                </TableCell>
                            </TableRow>
                        )

                    ))}
                    <TableRow>
                        <TableCell align="left">Total</TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">{totalVotes}</TableCell>
                        <TableCell align="left">100%</TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                </TableBody>
            </TableContainer>
        </>

    )
}

export default ElectionBox